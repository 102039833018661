import managementApi from '@/api/managementApi';
import productionApi from '@/api/productionApi';
import organizationApi from '@/api/organizationApi';

let GetAnualBillableIndicationCounter = 0;
let GetAssigmentHoursIndicationCounter = 0;
let GetAwaitingRevisionHoursIndicationCounter = 0;
let GetCompleteActivitiesIndicationCounter = 0;
let GetEfficiencyIndicationCounter = 0;
let GetHistoryOportunityIndicationCounter = 0;
let GetTimeBillableIndicationCounter = 0;
let GetTimeNoBillableIndicationCounter = 0;
let GetTotalSearchActivitiesCounter = 0;
let GetTotalSearchActivitiesCounterRevision = 0;
let GetAnualBillableIndicationController = new AbortController();
let GetAssigmentHoursIndicationController = new AbortController();
let GetAwaitingRevisionHoursIndicationController = new AbortController();
let GetCompleteActivitiesIndicationController = new AbortController();
let GetEfficiencyIndicationController = new AbortController();
let GetHistoryOportunityIndicationController = new AbortController();
let GetTimeBillableIndicationController = new AbortController();
let GetTimeNoBillableIndicationController = new AbortController();
let GetTotalSearchActivitiesAbortController = new AbortController();
let GetTotalSearchActivitiesRevisionAbortController = new AbortController();

//nuevo indicadores
let newTimeBillableIndicatorCounter = 0;
let newTimeNoBillableIndicatorController = new AbortController();

let newTimeNotBillableIndicatorCounter = 0;
let newTimeNotBillableIndicatorController = new AbortController();

let newEfficiencyIndicatorCounter = 0;
let newEfficiencyIndicatorController = new AbortController();

let newHistoricOpportunityIndicatorCounter = 0;
let newHistoricOpportunityIndicatorController = new AbortController();

let newAnualBillableIndicatorCounter = 0;
let newAnualBillableIndicatorController = new AbortController();

let newCompleteActivitiesIndicatorCounter = 0;
let newCompleteActivitiesIndicatorController = new AbortController();

let newHoursAssignedIndicatorCounter = 0;
let newHoursAssignedIndicatorController = new AbortController();

let newHoursWorkedIndicatorCounter = 0;
let newHoursWorkedIndicatorController = new AbortController();

export const cancelDashboardRequestNewIndicators = async () => {
    newTimeNoBillableIndicatorController.abort();
    newTimeNotBillableIndicatorController.abort();
    newEfficiencyIndicatorController.abort();
    newHistoricOpportunityIndicatorController.abort();
    newAnualBillableIndicatorController.abort();
    newHoursWorkedIndicatorController.abort();
    newCompleteActivitiesIndicatorController.abort();
    newHoursAssignedIndicatorController.abort();
};

// #region OldIndicators

export const cancelDashboardRequests = async () => {
    GetTotalSearchActivitiesAbortController.abort();
    GetTimeBillableIndicationController.abort();
    GetTimeNoBillableIndicationController.abort();
    GetHistoryOportunityIndicationController.abort();
    GetCompleteActivitiesIndicationController.abort();
    GetAnualBillableIndicationController.abort();
    GetAssigmentHoursIndicationController.abort();
    GetAwaitingRevisionHoursIndicationController.abort();
    GetEfficiencyIndicationController.abort();
    GetTotalSearchActivitiesRevisionAbortController.abort();
};

export const getOperativeActivitiesByUsers = async ({ commit }) => {
    const { data } = await productionApi.get(`OperativeActivitiesByUsers`);
    commit('setlistActivities', data.data);
};

export const getGrid = async ({ commit }, configObject) => {
    const { data } = await organizationApi.post(
        `GridDefinition/SearchGridDefinition`,
        configObject,
    );
    commit('setGrid', data.data[0]);
};
let data2;
export const GetFilterWorkGroupsByUserId = async ({ commit }, data) => {
    const response = await managementApi
        .post(`User/GetFilterWorkGroupsByUserId`, data)
        .then((response) => {
            if (response.status === 200) {
                let loadOptions = {
                    groups: data.groups,
                    subareas: data.subareas,
                };
                data2 = response.data.data;
                if (data.removed == true) {
                    return productionApi.post(
                        `OperativeActivitiesByUsers/GetFilterWorkGroupsByUserIdRemoved`,
                        loadOptions,
                    );
                } else {
                    return { success: true, codError: 0, data: [] };
                }
            } else {
                return { success: false, codError: 1, data: null };
            }
        })
        .then((res) => {
            let currentTeams = data2;
            if (data.removed == true) {
                currentTeams = [...currentTeams, ...res.data.data];
            }
            localStorage.setItem('teams', JSON.stringify(currentTeams));
            commit('setFilterTeams', currentTeams);
        })
        .catch((error) => {
            return { success: false, codError: 1, data: null };
        });
    return response;
};
export const GetFilterAreasByUserId = async ({ commit }, data) => {
    const response = await managementApi
        .get(`User/GetFilterAreasByUserId/${data.userId}/${data.company}`)
        .then((response) => {
            if (response.status === 200) {
                localStorage.setItem(
                    'areas',
                    JSON.stringify(response.data.data),
                );
                commit('setFilterAreas', response.data.data);
                return response.data;
            } else {
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const GetFilterClientsByUserId = async ({ commit }, data) => {
    const response = await managementApi
        .get(`User/GetFilterClientsByUserId/${data.userId}/${data.company}`)
        .then((response) => {
            if (response.status === 200) {
                localStorage.setItem(
                    'razonesSocial',
                    JSON.stringify(response.data.data),
                );
                commit('setFilterRazonesSocial', response.data.data);
                return response.data;
            } else {
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const GetDataDashboardWorkFlows = async ({ commit }, data) => {
    if (data != null) {
        const response = await productionApi
            .post(`OperativeWorkFlows/SearchOperativeWorkFlow`, data)
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    return { success: false, codError: 1, data: null };
                }
            })
            .catch((error) => {
                return { success: false, codError: 1, data: null };
            });
        return response.data;
    }
};

export const GetCompleteInfoOWF = async ({ commit }, data) => {
    if (data != null) {
        const response = await managementApi
            .post(`WorkFlows/CompleteInfoWF`, data)
            .then((response) => {
                if (response.status === 200) {
                    commit('setlistWorkflows', response.data.data);
                    return response.data;
                } else {
                    return { success: false, codError: 1, data: null };
                }
            })
            .catch((error) => {
                return { success: false, codError: 1, data: null };
            });
        return response;
    }
};

export const GetCompleteInfoOABU = async ({ commit }, data) => {
    if (data != null) {
        const response = await managementApi
            .post(`Activities/CompleteInfoOABU`, data)
            .then((response) => {
                if (response.status === 200) {
                    ///commit('setlistActivities', response.data.data);
                    return response.data;
                } else {
                    return { success: false, codError: 1, data: null };
                }
            })
            .catch((error) => {
                return { success: false, codError: 1, data: null };
            });
        return response;
    }
};

export const GetDataDashboard = async ({ commit }, data) => {
    const response = await productionApi
        .post(`OperativeActivitiesByUsers/SearchActivities`, data)
        //.get(`OperativeActivitiesByUsers`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const GetDataDashboardAll = async ({ commit }, data) => {
    const response = await productionApi
        .post(`OperativeActivitiesByUsers/SearchActivitiesAll`, data)
        //.get(`OperativeActivitiesByUsers`)
        .then((response) => {
            if (response.status === 200) {
                commit('setlistActivities', response.data.data);
                return response.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const GetDataCases = async ({ commit }, data) => {
    const response = await productionApi
        .post(`Process/SearchProcess`, data)
        .then((response) => {
            if (response.status === 200) {
                commit('setlistCases', response.data.data);
                return response.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const GetFilterMetaRangeByUser = async ({ commit }, data) => {
    const response = await managementApi
        .post(`User/GetFilterMetaRangeByUser`, data)
        .then((response) => {
            if (response.status === 200) {
                commit('setMetaRangeByUser', response.data.data);
                return response.data.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const GetTotalSearchActivities = async ({ commit }, data) => {
    GetTotalSearchActivitiesCounter++;
    GetTotalSearchActivitiesAbortController.abort();
    GetTotalSearchActivitiesAbortController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/TotalSearchActivities`,
            data,
            {
                signal: GetTotalSearchActivitiesAbortController.signal,
            },
        );
        if (
            GetTotalSearchActivitiesCounter === GetTotalSearchActivitiesCounter
        ) {
            if (response.status === 200) {
                commit('setTotalSearchActivities', response.data.data);
                return response.data;
            } else {
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetTotalSearchActivitiesDuplicate = async ({ commit }, data) => {
    GetTotalSearchActivitiesCounter++;
    GetTotalSearchActivitiesAbortController.abort();
    GetTotalSearchActivitiesAbortController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/TotalSearchActivitiesDuplicate`,
            data,
            {
                signal: GetTotalSearchActivitiesAbortController.signal,
            },
        );
        if (
            GetTotalSearchActivitiesCounter === GetTotalSearchActivitiesCounter
        ) {
            if (response.status === 200) {
                commit('setTotalSearchActivities', response.data.data);
                return response.data;
            } else {
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const TotalSearchActivitiesByUsersInRevision = async ({ commit }) => {
    GetTotalSearchActivitiesCounterRevision++;
    GetTotalSearchActivitiesRevisionAbortController.abort();
    GetTotalSearchActivitiesRevisionAbortController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/TotalSearchActivitiesByUsersInRevision`,
            {
                signal: GetTotalSearchActivitiesRevisionAbortController.signal,
            },
        );
        if (
            GetTotalSearchActivitiesCounterRevision ===
            GetTotalSearchActivitiesCounterRevision
        ) {
            if (response.status === 200) {
                commit(
                    'setTotalSearchActivitiesInRevision',
                    response.data.data,
                );
                return response.data;
            } else {
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetTimeBillableIndication = async ({ commit }, data) => {
    GetTimeBillableIndicationCounter++;
    GetTimeBillableIndicationController.abort();
    GetTimeBillableIndicationController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/TimeBillableIndication`,
            data,
            {
                signal: GetTimeBillableIndicationController.signal,
            },
        );
        if (
            GetTimeBillableIndicationCounter ===
            GetTimeBillableIndicationCounter
        ) {
            if (response.status === 200) {
                commit('setTimeBillableIndication', response.data.data);
                commit('setLoadingIndicator', 1);
                return response.data;
            } else {
                commit('setLoadingIndicator', 1);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetTimeNoBillableIndication = async ({ commit }, data) => {
    GetTimeNoBillableIndicationCounter++;
    GetTimeNoBillableIndicationController.abort();
    GetTimeNoBillableIndicationController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/TimeNoBillableIndication`,
            data,
            {
                signal: GetTimeNoBillableIndicationController.signal,
            },
        );
        if (
            GetTimeNoBillableIndicationCounter ===
            GetTimeNoBillableIndicationCounter
        ) {
            if (response.status === 200) {
                commit('setTimeNoBillableIndication', response.data.data);
                commit('setLoadingIndicator', 2);
            } else {
                commit('setLoadingIndicator', 2);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetHistoryOportunityIndication = async ({ commit }, data) => {
    GetHistoryOportunityIndicationCounter++;
    GetHistoryOportunityIndicationController.abort();
    GetHistoryOportunityIndicationController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/HistoryOportunityIndication`,
            data,
            {
                signal: GetHistoryOportunityIndicationController.signal,
            },
        );
        if (
            GetHistoryOportunityIndicationCounter ===
            GetHistoryOportunityIndicationCounter
        ) {
            if (response.status === 200) {
                commit('setHistoryOportunityIndication', response.data.data);
                commit('setLoadingIndicator', 3);
                return response.data;
            } else {
                // console.log.log(response);
                commit('setLoadingIndicator', 3);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetCompleteActivitiesIndication = async ({ commit }, data) => {
    GetCompleteActivitiesIndicationCounter++;
    GetCompleteActivitiesIndicationController.abort();
    GetCompleteActivitiesIndicationController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/CompleteActivitiesIndication`,
            data,
            {
                signal: GetCompleteActivitiesIndicationController.signal,
            },
        );
        if (
            GetCompleteActivitiesIndicationCounter ===
            GetCompleteActivitiesIndicationCounter
        ) {
            if (response.status === 200) {
                commit('setCompleteActivitiesIndication', response.data.data);
                commit('setLoadingIndicator', 4);
                return response.data;
            } else {
                commit('setLoadingIndicator', 4);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetAnualBillableIndication = async ({ commit }, data) => {
    GetAnualBillableIndicationCounter++;
    GetAnualBillableIndicationController.abort();
    GetAnualBillableIndicationController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/AnualBillableIndication`,
            data,
            {
                signal: GetAnualBillableIndicationController.signal,
            },
        );
        if (
            GetAnualBillableIndicationCounter ===
            GetAnualBillableIndicationCounter
        ) {
            if (response.status === 200) {
                commit('setAnualBillableIndication', response.data.data);
                commit('setLoadingIndicator', 5);
                return response.data;
            } else {
                commit('setLoadingIndicator', 5);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetAssigmentHoursIndication = async ({ commit }, data) => {
    GetAssigmentHoursIndicationCounter++;
    GetAssigmentHoursIndicationController.abort();
    GetAssigmentHoursIndicationController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/AssigmentHoursIndication`,
            data,
            {
                signal: GetAssigmentHoursIndicationController.signal,
            },
        );
        if (
            GetAssigmentHoursIndicationCounter ===
            GetAssigmentHoursIndicationCounter
        ) {
            if (response.status === 200) {
                commit('setAssigmentHoursIndication', response.data.data);
                commit('setLoadingIndicator', 6);
                return response.data;
            } else {
                commit('setLoadingIndicator', 6);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetAwaitingRevisionHoursIndication = async ({ commit }, data) => {
    GetAwaitingRevisionHoursIndicationCounter++;
    GetAwaitingRevisionHoursIndicationController.abort();
    GetAwaitingRevisionHoursIndicationController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/AwaitingRevisionHoursIndication`,
            data,
            {
                signal: GetAwaitingRevisionHoursIndicationController.signal,
            },
        );
        if (
            GetAwaitingRevisionHoursIndicationCounter ===
            GetAwaitingRevisionHoursIndicationCounter
        ) {
            if (response.status === 200) {
                commit(
                    'setAwaitingRevisionHoursIndication',
                    response.data.data,
                );
                commit('setLoadingIndicator', 7);
                return response.data;
            } else {
                // console.log.log(response);
                commit('setLoadingIndicator', 7);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetEfficiencyIndication = async ({ commit }, data) => {
    GetEfficiencyIndicationCounter++;
    GetEfficiencyIndicationController.abort();
    GetEfficiencyIndicationController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/EfficiencyIndication`,
            data,
            {
                signal: GetEfficiencyIndicationController.signal,
            },
        );
        if (GetEfficiencyIndicationCounter === GetEfficiencyIndicationCounter) {
            if (response.status === 200) {
                commit('setEfficiencyIndication', response.data.data);
                commit('setLoadingIndicator', 8);
                return response.data;
            } else {
                commit('setLoadingIndicator', 8);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetAssignedActivitiesToCalendar = async ({ commit }, data) => {
    const response = await productionApi
        .post(
            `OperativeActivitiesByUsers/GetAssignedActivitiesToCalendar`,
            data,
        )
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const GetFinishedActivitiesToCalendar = async ({ commit }, data) => {
    const response = await productionApi
        .post(
            `OperativeActivitiesByUsers/GetFinishedActivitiesToCalendar`,
            data,
        )
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const GetInProcessActivitiesToCalendar = async ({ commit }, data) => {
    const response = await productionApi
        .post(
            `OperativeActivitiesByUsers/GetInProcessActivitiesToCalendar`,
            data,
        )
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const GetEstimatedTimeByRangeDate = async ({ commit }, data) => {
    const response = await productionApi
        .post(`OperativeActivitiesByUsers/GetEstimatedTimeByRangeDate`, data)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const GetBillableTimeByDateRange = async ({ commit }, data) => {
    const response = await productionApi
        .post(`OperativeActivitiesByUsers/GetBillableTimeByDateRange`, data)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const GetNoBillableTimeByDateRange = async ({ commit }, data) => {
    const response = await productionApi
        .post(`OperativeActivitiesByUsers/GetNoBillableTimeByDateRange`, data)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const AddTimeToActivity = async ({ commit }, data) => {
    const response = await productionApi
        .post(
            `OperativeActivitiesByUsers/RegisterHoursOperativeActivity/${data.activityId}`,
            data.hours,
        )
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                // console.log.log(response);
                return { success: false, codError: 1, data: null };
            }
        })
        .catch((error) => {
            // console.log.log(error);
            return { success: false, codError: 1, data: null };
        });
    return response;
};

export const GetTimesByUsers = async ({ commit }, dataSend) => {
    const { data } = await productionApi.post(
        `OperativeActivitiesByUsers/GetTimesByUsers`,
        dataSend,
    );
    commit('setTimesByUsers', data.data);
};

export const getActivitiesByFlowIdForGrid = async ({ commit }, data) => {
    if (data != null) {
        const response = await productionApi
            .get(
                `OperativeActivitiesByUsers/GetActivitiesByFlowIdForGrid/${data}`,
            )
            .then((response) => {
                if (response.status === 200) {
                    ///commit('setlistActivities', response.data.data);
                    return response.data;
                } else {
                    return { success: false, codError: 1, data: null };
                }
            })
            .catch((error) => {
                return { success: false, codError: 1, data: null };
            });
        return response.data;
    }
};
//settings
export const UpdateAllNotificationsUser = async ({ commit }, userId) => {
    if (userId) {
        const response = await productionApi
            .post(
                `OperativeNotifications/UpdateStatusNotificationAllByUserId/${userId}`,
            )
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    return { success: false, codError: 1, data: null };
                }
            })
            .catch((error) => {
                return { success: false, codError: 1, data: null };
            });

        return response.data;
    }
};

export const UpdateSingleNotificationsUser = async (
    { commit },
    NotificationId,
) => {
    if (NotificationId) {
        const response = await productionApi
            .post(
                `OperativeNotifications/UpdateStatusNotificationById/${NotificationId}`,
            )
            .then((response) => {
                if (response.status === 200) {
                    ///commit('setlistActivities', response.data.data);
                    return response.data;
                } else {
                    return { success: false, codError: 1, data: null };
                }
            })
            .catch((error) => {
                return { success: false, codError: 1, data: null };
            });

        return response.data;
    }
};

export const UpdateSingleNotificationsStatusDeleteUser = async (
    { commit },
    NotificationId,
) => {
    if (NotificationId) {
        const response = await productionApi
            .post(
                `OperativeNotifications/UpdateStatusDeleteNotificationById/${NotificationId}`,
            )
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    return { success: false, codError: 1, data: null };
                }
            })
            .catch((error) => {
                return { success: false, codError: 1, data: null };
            });

        return response.data;
    }
};

// #endregion

// #region new endpoints Indicators

export const GetNewTimeBillableIndicator = async ({ commit }, data) => {
    newTimeBillableIndicatorCounter++;
    newTimeNoBillableIndicatorController.abort();
    newTimeNoBillableIndicatorController = new AbortController();
    try {
        const response = await productionApi.post(
            `TimeBillableIndicator/GetTimeBillableIndicator`,
            data,
            {
                signal: newTimeNoBillableIndicatorController.signal,
            },
        );
        if (
            newTimeBillableIndicatorCounter === newTimeBillableIndicatorCounter
        ) {
            if (response.status === 200) {
                commit('setTimeBillableIndication', response.data.data);
                commit('setLoadingIndicator', 1);
                return response.data;
            } else {
                commit('setLoadingIndicator', 1);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetNewTimeNotBillableIndicator = async ({ commit }, data) => {
    newTimeNotBillableIndicatorCounter++;
    newTimeNotBillableIndicatorController.abort();
    newTimeNotBillableIndicatorController = new AbortController();

    try {
        const response = await productionApi.post(
            `TimeNotBillableIndicator/GetTimeNotBillableIndicator`,
            data,
            {
                signal: newTimeNotBillableIndicatorController.signal,
            },
        );
        if (
            newTimeNotBillableIndicatorCounter ===
            newTimeNotBillableIndicatorCounter
        ) {
            if (response.status === 200) {
                commit('setTimeNoBillableIndication', response.data.data);
                commit('setLoadingIndicator', 2);
            } else {
                commit('setLoadingIndicator', 2);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetNewEfficiencyIndicator = async ({ commit }, data) => {
    newEfficiencyIndicatorCounter++;
    newEfficiencyIndicatorController.abort();
    newEfficiencyIndicatorController = new AbortController();

    try {
        const response = await productionApi.post(
            `EfficiencyIndicator/GetEfficiencyIndicator`,
            data,
            {
                signal: newEfficiencyIndicatorController.signal,
            },
        );
        if (newEfficiencyIndicatorCounter === newEfficiencyIndicatorCounter) {
            if (response.status === 200) {
                console.log(
                    'response.data.data eficiencia: ',
                    response.data.data,
                );
                commit('setEfficiencyIndication', response.data.data);
                commit('setLoadingIndicator', 3);
                return response.data;
            } else {
                commit('setLoadingIndicator', 3);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetNewHistoryOportunityIndicator = async ({ commit }, data) => {
    newHistoricOpportunityIndicatorCounter++;
    newHistoricOpportunityIndicatorController.abort();
    newHistoricOpportunityIndicatorController = new AbortController();
    try {
        const response = await productionApi.post(
            `HistoricOpportunityIndicator/GetHistoricOpportunityIndicator`,
            data,
            {
                signal: newHistoricOpportunityIndicatorController.signal,
            },
        );
        if (
            newHistoricOpportunityIndicatorCounter ===
            newHistoricOpportunityIndicatorCounter
        ) {
            if (response.status === 200) {
                commit('setHistoryOportunityIndication', response.data.data);
                commit('setLoadingIndicator', 3);
                return response.data;
            } else {
                // console.log.log(response);
                commit('setLoadingIndicator', 3);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetNewAnualBillableIndication = async ({ commit }, data) => {
    newAnualBillableIndicatorCounter++;
    newAnualBillableIndicatorController.abort();
    newAnualBillableIndicatorController = new AbortController();
    try {
        const response = await productionApi.post(
            `AnualBillableIndicator/GetValueAnualBillableIndicator`,
            data,
            {
                signal: newAnualBillableIndicatorController.signal,
            },
        );
        if (
            newAnualBillableIndicatorCounter ===
            newAnualBillableIndicatorCounter
        ) {
            if (response.status === 200) {
                commit('setAnualBillableIndication', response.data.data);
                commit('setLoadingIndicator', 5);
                return response.data;
            } else {
                commit('setLoadingIndicator', 5);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetNewCompleteActivitiesIndication = async ({ commit }, data) => {
    newCompleteActivitiesIndicatorCounter++;
    newCompleteActivitiesIndicatorController.abort();
    newCompleteActivitiesIndicatorController = new AbortController();
    try {
        const response = await productionApi.post(
            `CompleteActivitiesIndicator/GetValueCompleteActivitiesIndicator`,
            data,
            {
                signal: newCompleteActivitiesIndicatorController.signal,
            },
        );
        if (
            newCompleteActivitiesIndicatorCounter ===
            newCompleteActivitiesIndicatorCounter
        ) {
            if (response.status === 200) {
                commit('setCompleteActivitiesIndication', response.data.data);
                commit('setLoadingIndicator', 4);
                return response.data;
            } else {
                commit('setLoadingIndicator', 4);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetNewAssigmentHoursIndication = async ({ commit }, data) => {
    newHoursAssignedIndicatorCounter++;
    newHoursAssignedIndicatorController.abort();
    newHoursAssignedIndicatorController = new AbortController();
    try {
        const response = await productionApi.post(
            `HoursAssignedIndicator/GetValueHoursAssignedIndicator`,
            data,
            {
                signal: newHoursAssignedIndicatorController.signal,
            },
        );
        if (
            newHoursAssignedIndicatorCounter ===
            newHoursAssignedIndicatorCounter
        ) {
            if (response.status === 200) {
                commit('setAssigmentHoursIndication', response.data.data);
                commit('setLoadingIndicator', 7);
                return response.data;
            } else {
                commit('setLoadingIndicator', 7);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

export const GetNewHoursWorkedIndication = async ({ commit }, data) => {
    newHoursWorkedIndicatorCounter++;
    newHoursWorkedIndicatorController.abort();
    newHoursWorkedIndicatorController = new AbortController();
    try {
        const response = await productionApi.post(
            `HoursWorkedIndicator/GetValueHoursWorkedIndicator`,
            data,
            {
                signal: newHoursWorkedIndicatorController.signal,
            },
        );
        if (newHoursWorkedIndicatorCounter === newHoursWorkedIndicatorCounter) {
            if (response.status === 200) {
                commit('setWorkedsHoursIndication', response.data.data);
                commit('setLoadingIndicator', 8);
                return response.data;
            } else {
                commit('setLoadingIndicator', 8);
                return { success: false, codError: 1, data: null };
            }
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return { success: false, codError: 1, data: null };
        }
    }
};

// #endregion
