import permissionsManagement from '@/router/auth-guard';
export default {
    name: 'settlement-billing',
    component: () =>
        import(
            /* webpackChunkName: "settlement-billing" */ '@/modules/settlement-billing/layouts/SettlementBillingLayout.vue'
        ),
    children: [
        {
            path: 'audited-by-leader',
            name: 'audited-by-leader',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementAccessReviewedServicesAll',
                    'SettlementAccessReviewedServicesWorkgroup',
                ],
                father: 'settlement'
            },
            component: () =>
                import(
                    /* webpackChunkName: "audited-by-leader" */
                    '@/modules/settlement-billing/views/AuditedByLeader/AuditedByLeader.vue'
                ),
        },
        {
            path: 'audited-by-leader/detail',
            name: 'detail-audited-by-leader',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementAccessReviewedServicesAll',
                    'SettlementAccessReviewedServicesWorkgroup',
                ],
                father: 'settlement'
            },
            component: () =>
                import(
                    /* webpackChunkName: "audited-by-leader-detail" */
                    '@/modules/settlement-billing/views/AuditedByLeader/AuditedByLeaderDetail.vue'
                ),
        },
        {
            path: 'awaiting-preinvoice',
            name: 'awaiting-preinvoice',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadPendingPreinvoicesAll',
                    'SettlementReadPendingPreinvoicesWorkgroup',
                ],
                father: 'settlement'
            },
            component: () =>
                import(
                    '@/modules/settlement-billing/views/AwaitingPreinvoice/AwaitingPreinvoice.vue'
                ),
        },
        {
            path: 'awaiting-preinvoice/detail',
            name: 'detail-awaiting-preinvoice',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadPendingPreinvoicesAll',
                    'SettlementReadPendingPreinvoicesWorkgroup',
                ],
                father: 'settlement'
            },
            component: () =>
                import(
                    '@/modules/settlement-billing/views/AwaitingPreinvoice/AwaitingPreinvoiceDetail.vue'
                ),
        },
        {
            path: 'preinvoice',
            name: 'preinvoice',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadPreinvoicesAll',
                    'SettlementReadPreinvoicesWorkgroup',
                ],
                father: 'settlement'
            },
            component: () =>
                import(
                    '@/modules/settlement-billing/views/PreInvoice/Preinvoice-component.vue'
                ),
        },
        {
            path: 'preinvoice/detail',
            name: 'detail-preinvoice',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadPreinvoicesAll',
                    'SettlementReadPreinvoicesWorkgroup',
                ],
                father: 'settlement'
            },
            component: () =>
                import(
                    '@/modules/settlement-billing/views/PreInvoice/PreinvoiceDetail.vue'
                ),
        },
        {
            path: 'invoice',
            name: 'invoice',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadLiquidatedAll',
                    'SettlementReadLiquidatedWorkgroup',
                ],
                father: 'settlement'
            },
            component: () =>
                import(
                    '@/modules/settlement-billing/views/Invoice/InvoiceComponent.vue'
                ),
        },
        {
            path: 'invoice/detail',
            name: 'detail-invoice',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadLiquidatedAll',
                    'SettlementReadLiquidatedWorkgroup',
                ],
                father: 'settlement'
            },
            component: () =>
                import(
                    '@/modules/settlement-billing/views/Invoice/InvoiceDetail.vue'
                ),
        },
        {
            path: 'specialActivation',
            name: 'specialActivation',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadFutureBlocksAll',
                    'SettlementReadFutureBlocksWorkgroup',
                ],
                father: 'settlement'
            },
            component: () =>
                import(
                    '@/modules/settlement-billing/views/SearchBlockForSpecialActivation/SearchBloksForSpecialActivation.vue'
                ),
        },
        {
            path: 'creditNotes',
            name: 'creditNotes',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementCreditNotesAll',
                    'SettlementCreditNotesWorkgroup',
                ],
                father: 'settlement'
            },
            component: () =>
                import(
                    '@/modules/settlement-billing/views/CreditNotes/CreditNotes.vue'
                ),
        },
        {
            path: 'creditNotes/detail',
            name: 'view-credit-note',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementCreditNotesAll',
                    'SettlementCreditNotesWorkgroup',
                ],
                father: 'settlement'
            },
            component: () =>
                import(
                    '@/modules/settlement-billing/views/CreditNotes/CreditNotesDetail.vue'
                ),
        },
        {
            path: 'cash-register-receipt',
            name: 'cash-register-receipt',
            meta: {
                father: 'settlement'
            },
            component: () =>
                import(
                    '@/modules/settlement-billing/views/CashRegisterReceipt/CashRegisterReceipt.vue'
                ),
        },
        {
            path: 'cash-register-receipt-detail',
            name: 'cash-register-receipt-detail',
            meta: {
                father: 'settlement'
            },
            component: () =>
                import(
                    '@/modules/settlement-billing/views/CashRegisterReceipt/CashRegisterReceiptDetail.vue'
                ),
        },
    ],
};
